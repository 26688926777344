@import '~antd/dist/antd.less';
@import url('https://use.typekit.net/fcm0zpm.css');
@import url('https://use.typekit.net/umg4wnw.css');

body {
	margin: 0;
	font-family: 'Poppins', 'libre-franklin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Ant design theme changes

.ant-checkbox-inner {
	border-radius: 2px;
}
.ant-form-item-explain.ant-form-item-explain-error {
	font-size: 10px;
}

.ant-upload.ant-upload-drag,
.ant-form-item .ant-upload.ant-upload-drag {
	background: #fff;
	border-color: #d2d8e0;
	.ant-upload.ant-upload-btn {
		padding: 0;
	}
}

.clickable {
	&:hover {
		cursor: pointer;
	}
}

// Table Styles
.ant-table {
	overflow-x: auto;
}
.ant-table table {
	border-spacing: 0 8px;
	border-radius: 0;

	// Expanded row
	.expanded-row {
		.ant-table-cell {
			background: #edeff2;
		}
	}

	.expandable-row {
		.ant-table-cell {
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.ant-table-tbody > tr.ant-table-row.expanded-row:hover > td {
	cursor: pointer;
	background: #edeff2;
}
// Expanded row white
.table-expanded-white {
	.ant-table table {
		.expanded-row {
			.ant-table-cell {
				background: #fff;
			}
		}
	}
	.ant-table-tbody > tr.ant-table-row.expanded-row:hover > td {
		background: #fff;
	}
}

.ant-table-container table > thead > tr:first-child th:first-child,
.ant-table-tbody > tr > td:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}
.ant-table-container table > thead > tr:first-child th:last-child,
.ant-table-tbody > tr > td:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.ant-table-container table > thead > tr th:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.ant-table-tbody > tr > td {
	background: #fff;
	border: none;
}
.ant-table-thead > tr > th {
	padding: 10px 16px;
}
.ant-table-tbody > tr > td {
	padding: 18px 16px;
}
.ant-table-tbody {
	.drop-over-downward > td {
		border-bottom: 1px solid #1581ff;
	}

	.drop-over-upward > td {
		border-top: 1px solid #1581ff;
	}
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	font-size: 12px;
}
.ant-table-expanded-row {
	.ant-table-cell {
		padding: 0;
	}
}
.ant-table-row.highlighted {
	.ant-table-cell {
		border-bottom: 1px solid #1581ff;
		border-top: 1px solid #1581ff;
		&:first-child {
			border-left: 1px solid #1581ff;
		}
		&:last-child {
			border-right: 1px solid #1581ff;
		}
	}
}
.ant-table-row {
	break-inside: avoid;
}

// Select
.ant-select-item {
	color: #808fad;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: rgba(21, 129, 255, 0.04);
	border-radius: 4px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	border-radius: 4px;
	font-weight: 400;
	background-color: rgba(21, 129, 255, 0.16);
}
.ant-select-multiple .ant-select-selection-item {
	background: transparent;
	border-radius: 16px;
	border: 1px solid #d3d8e0;
}
.ant-select-multiple .ant-select-selection-item-content {
	font-size: 12px;
	font-weight: 400;
	color: #808fad;
}
// Problem with showing last item
.ant-select-item {
	margin: 2px 0;
}
.rc-virtual-list-holder-inner {
	padding: 8px;
}

.no-spaces {
	.ant-select-item {
		margin: 0 0;
	}
	.rc-virtual-list-holder-inner {
		padding: 0;
	}
}

// Switch
.ant-switch {
	background-color: #d3d8e0;
}
.ant-switch-checked {
	background-color: #1581ff;
}

// Badge
.ant-badge-count-sm {
	padding-top: 1px;
	border: none;
}

// Modals
.ant-modal-close {
	top: 20px;
	right: 30px;
	.ant-modal-close-x {
		width: 30px;
		height: 30px;
		line-height: 30px;
		font-size: 10px;
	}
}

// Dropdown fix
.z-index-5 {
	z-index: 5;
}

// Tabs
.ant-tabs-top > .ant-tabs-nav {
	margin-bottom: 24px;
}

.asset-discovery-notification, .download-pdf-notification {
	background: #af5fff;
	.ant-notification-notice-message {
		color: #fff;
		font-size: 14px;
		margin-bottom: 0;
	}
	.ant-notification-notice-close {
		color: #fff;
		top: 17px;
	}
	.ant-btn-link {
		color: #fff;
		padding-top: 0;
		padding-bottom: 0;
		height: 24px;
	}
}

.download-pdf-notification {
	width: 400px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
	display: flex;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	display: flex;
}

.ant-input-number-affix-wrapper::before {
	content: none;
}

.ant-table table {
	border-spacing: 0 8px;
	border-radius: 0;

	// Delted row
	.deleted-row {
		.ant-table-cell {
			opacity: 0.5;
		}
	}
}

.ant-table-tbody > tr.ant-table-row.deleted-row:hover > td {
	cursor:auto;
	opacity: 0.5;
}
.no-break-inside {
	// apply this class to every component that shouldn't be cut off between to pages of your PDF
	break-inside: avoid;
}

.break-before {
	// apply this class to every component that should always display on next page
	break-before: always;
}
.expandable-content {
	background-color: #f9f9f9;
	padding: 0;
  }
  .expanded-row td {
	background-color: #e6f7ff;
  }
// .no-top-border > td {
// 	border-top: none !important;
// 	position: absolute;
// 	top: -18px;
// 	.ant-table-cell {
// 	border-top-left-radius: 0px;
// 	border-top-left-radius: 0px;
// 	border-bottom-left-radius: 8px;
// 	border-bottom-left-radius: 8px;
// 	}
// }
.main-row {
	cursor: pointer;
  }
  
  .expandable-content-row {
	background-color: #f9f9f9;
  }
@media print {
  body {
    background: #F0F3F7 !important;
  }
  //TODO: hide buttons and other elements here instead of passing the isUsedForExports flag
}

@primary-color: #1581FF;@error-color: #ff586c;@success-color: #23C7B6;@layout-footer-background: #F0F3F7;@layout-body-background: #F0F3F7;@layout-sider-background: #001433;@wave-animation-width: 0;@border-radius-base: 6px;@border-radius-sm: 6px;@btn-font-weight: 500;@btn-border-width: 0;@btn-height-base: 40px;@btn-padding-horizontal-base: 24px;@btn-height-lg: 48px;@btn-padding-horizontal-lg: 24px;@btn-height-sm: 36px;@btn-padding-horizontal-sm: 24px;@btn-default-color: #1581FF;@btn-default-bg: rgba(38, 47, 103, 0.08);@font-family: Poppins;@input-placeholder-color: #808FAD;@text-color: #001433;@heading-color: #001433;@form-vertical-label-padding: 0;@form-item-label-font-size: 12px;@label-color: #263767;@heading-1-size: 32px;@heading-2-size: 24px;@heading-3-size: 20px;@heading-4-size: 18px;@heading-5-size: 16px;@heading-6-size: 14px;@steps-background: #F0F3F7;@input-height-base: 40px;@input-height-lg: 48px;@input-height-sm: 32px;@input-border-color: #D2D8E0;@text-color-secondary: #808FAD;@table-header-bg: #D2D8E0;@table-header-sort-bg: #D2D8E0;@table-header-sort-active-bg: #D2D8E0;@table-body-sort-bg: #FFF;@table-bg: transparent;@modal-body-padding: 40px;@badge-height-sm: 16px;@badge-font-size-sm: 7px;@badge-font-size: 10px;@radio-button-bg: #fff;@tabs-card-tab-active-border-top: 1px solid transparent;